import React from 'react';
import { useParams } from 'react-router-dom';
import RunCalculator from '../components/RunCalculator';

const RunPage = () => {
    const { runId } = useParams();

    return (
        <div>
            <RunCalculator runId={runId} />
        </div>
    );
};

export default RunPage;
