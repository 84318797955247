// PickupSelector.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';

const PickupSelector = ({
    index,
    choices,
    setChoices,
    winPercentages,
    setWinPercentages,
    selectedPickup,
    setSelectedPickup,
    modalOpen,
    setModalOpen,
    displayedPickups,
    setDisplayedPickups,
    pages,
    setPages,
    searches,
    setSearches,
    pickups,
    handlePickSelection,
    selectedDate // Add selectedDate as a prop
}) => {
    const pageSize = 10;
    const [isHovered, setIsHovered] = useState(false);

 useEffect(() => {
         updateWinPercentages();
  }, [selectedDate]);


    const filterAndPaginatePickups = (search, page) => {
        const filtered = pickups.filter(pickup =>
            pickup.name.toLowerCase().includes(search.toLowerCase())
        );
        const startIndex = page * pageSize;
        return filtered.slice(startIndex, startIndex + pageSize);
    };

    const handleSearchChange = (value) => {
        const newSearches = [...searches];
        newSearches[index] = value;
        setSearches(newSearches);

        const newDisplayedPickups = [...displayedPickups];
        newDisplayedPickups[index] = filterAndPaginatePickups(value, 0);
        setDisplayedPickups(newDisplayedPickups);

        const newPages = [...pages];
        newPages[index] = 0;
        setPages(newPages);
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            const newPage = pages[index] + 1;
            const newDisplayedPickups = [...displayedPickups];
            newDisplayedPickups[index] = [
                ...newDisplayedPickups[index],
                ...filterAndPaginatePickups(searches[index], newPage)
            ];
            setDisplayedPickups(newDisplayedPickups);
            const newPages = [...pages];
            newPages[index] = newPage;
            setPages(newPages);
        }
    };

    const getWinPercentageColor = (winPercentage) => {
    if(!winPercentage){
    return `rgb(90, 90, 90)`;
    }
        const green = Math.round((winPercentage / 120) * 200);
        const red = 255 - green;
        return `rgb(${red}, ${green}, 50)`;
    };
const darkenColor = (color, percent) => {
  // Extract the rgb components from the color string
  const colorArray = color.match(/\d+/g).map(Number);
  const [R, G, B] = colorArray;

  // Adjust each component to darken the color
  const adjust = (component) => Math.max(0, component + Math.round((percent / 100) * component));

  const newR = adjust(R);
  const newG = adjust(G);
  const newB = adjust(B);

  return `rgb(${newR}, ${newG}, ${newB})`;
};
    const handleChoiceChange = async (pickup) => {
        const newChoices = [...choices];
        newChoices[index] = pickup;
        setChoices(newChoices);

        const newSelectedPickup = [...selectedPickup];
        newSelectedPickup[index] = pickup;
        setSelectedPickup(newSelectedPickup);

        const newModalOpen = [...modalOpen];
        newModalOpen[index] = false;
        setModalOpen(newModalOpen);

        try {
            const params = {
                weaponId: localStorage.getItem('activeWeapon'),
                pickups: JSON.parse(localStorage.getItem('activeRun')),
                pickup: pickup.id
            };

            if (selectedDate) {
                params.date = selectedDate;
            }

            axios.get('http://161.35.120.216:8080/api/builds/score', {
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                }
            })
                .then(response => {
                    const newWinPercentages = [...winPercentages];
                    newWinPercentages[index] = response.data.winPercentage;
                    setWinPercentages(newWinPercentages);
                })
                .catch(error => {
                    console.error('Error fetching build score:', error);
                });
        } catch (error) {
            console.error('Error calculating win percentage:', error);
        }
    };
  const updateWinPercentages = () => {
        const weaponId = localStorage.getItem('activeWeapon');
        const pickups = JSON.parse(localStorage.getItem('activeRun'));

        if (choices[index]) {
            const params = {
                weaponId,
                pickups,
                pickup: choices[index].id
            };

            if (selectedDate) {
                params.date = selectedDate;
            }

            axios.get('http://161.35.120.216:8080/api/builds/score', {
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: 'repeat' });
                }
            })
            .then(response => {
                 setWinPercentages((prevWinPercentages) => {
                   const newWinPercentages = [...prevWinPercentages];
                   newWinPercentages[index] = response.data.winPercentage;
                   return newWinPercentages;
                 });
            })
            .catch(error => {
                console.error('Error fetching build score:', error);
            });
        }
    };
    const renderPickupBox = () => (
        <div className="pickup-box" style={{ borderColor: isHovered ?  darkenColor(getWinPercentageColor(winPercentages[index]), 50) : getWinPercentageColor(winPercentages[index]) }}onClick={() => {
            const newModalOpen = [...modalOpen];
            newModalOpen[index] = true;
            setModalOpen(newModalOpen);
        }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
            <div className="content">
                {choices[index] ? (
                    <>
                        <img src={`/images/pickups/${choices[index].name.replace(/\s+/g, '_')}.png`} alt={choices[index].name} className="pickup-icon" onError={(e) => e.target.src = '/images/pickups/undefined.png'} />
                        <div className="pickup-details">
                            <div className="pickup-name">{choices[index].name}</div>
                            <div className="pickup-description">{choices[index].description}</div>
                            <div className="pickup-subdescription">{choices[index].subdescription}</div>
                        </div>
                        {winPercentages[index] !== null && (
                            <div className="win-percentage right-align" style={{ color: getWinPercentageColor(winPercentages[index]) }}>
                                {winPercentages[index]}
                            </div>
                        )}
                        <button
                            className="pick-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                handlePickSelection(index);
                            }}
                        >
                            Pick
                        </button>
                    </>
                ) : (
                    <div>Select Boon...</div>
                )}
            </div>
        </div>
    );

    const renderModal = () => (
        <div className="modal-overlay" onClick={() => {
            const newModalOpen = [...modalOpen];
            newModalOpen[index] = false;
            setModalOpen(newModalOpen);
        }}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()} onScroll={handleScroll}>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search pickups..."
                        value={searches[index]}
                        onChange={(e) => handleSearchChange(e.target.value)}
                    />
                </div>
                {displayedPickups[index] && Array.isArray(displayedPickups[index]) && displayedPickups[index].map((pickup) => (
                    <div className="modal-box" key={pickup.id} onClick={() => handleChoiceChange(pickup)}>
                        <img src={`/images/pickups/${pickup.name.replace(' ', '_')}.png`} alt={pickup.name} className="pickup-icon" onError={(e) => e.target.src = '/images/pickups/undefined.png'} />
                        <div className="pickup-details">
                            <div className="pickup-name">{pickup.name}</div>
                            <div className="pickup-description">{pickup.description}</div>
                            <div className="pickup-subdescription">{pickup.subdescription}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <>
            {renderPickupBox()}
            {modalOpen[index] && renderModal()}
        </>
    );
};

export default PickupSelector;
