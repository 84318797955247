import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const WeaponsContext = createContext();

export const useWeapons = () => useContext(WeaponsContext);

export const WeaponsProvider = ({ children }) => {
    const [weapons, setWeapons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get('/data/weapons.json')
            .then(response => {
                setWeapons(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error loading weapons:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <WeaponsContext.Provider value={{ weapons, isLoading }}>
            {children}
        </WeaponsContext.Provider>
    );
};
