// CurrentPickups.js
import React, { useState, useEffect, useRef } from 'react';

const CurrentPickups = ({ currentPickups }) => {

    return (
        <div className="suggested-pickups">
            <h2>Current Boons</h2>
            <ul>
                {currentPickups.map(pickup => (
                    <li key={pickup.id} className="current-pickup-item">
                        <div className="pickup-image-container">
                            <img
                                src={`/images/pickups/${pickup.name.replace(' ', '_')}.png`}
                                alt={pickup.name}
                                className="current-pickup-icon"
                                onError={(e) => e.target.src = '/images/pickups/undefined.png'}
                            />
                            <img
                                src={`/images/gods/${pickup.god}.png`}
                                alt={`${pickup.god} icon`}
                                className="pickup-god-icon"
                                onError={(e) => e.target.src = '/images/gods/undefined.png'}
                            />
                            {pickup.god2 && (
                                <img
                                    src={`/images/gods/${pickup.god2}.png`}
                                    alt={`${pickup.god2} icon`}
                                    className="pickup-god2-icon"
                                    onError={(e) => e.target.src = '/images/gods/undefined.png'}
                                />
                            )}
                        </div>
                        <div className="tooltip">
                            <div className="pickup-name">{pickup.name}</div>
                            <div className="pickup-description">{pickup.description}</div>
                            <div className="pickup-subdescription">{pickup.subdescription}</div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CurrentPickups;
