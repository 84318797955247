import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const PickupsContext = createContext();

export const usePickups = () => useContext(PickupsContext);

export const PickupsProvider = ({ children }) => {
    const [pickups, setPickups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios.get('/data/pickups.json')
            .then(response => {
                const modifiedData = response.data.map(item => ({
                    ...item,
                    name: item.name.replace(/_/g, ' ')
                }));
                setPickups(modifiedData);
                console.error('Lol pickups:', modifiedData);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error loading pickups:', error);
                setIsLoading(false);
            });
    }, []);

    return (
        <PickupsContext.Provider value={{ pickups, isLoading }}>
            {children}
        </PickupsContext.Provider>
    );
};
