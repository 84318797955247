// SuggestedPickups.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';

const SuggestedPickups = ({
    suggestedPickups,
    setSuggestedPickups, // Add setSuggestedPickups as a prop
    pickups,
    currentPickups,
    selectedDate // Add selectedDate as a prop
}) => {
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage, isLoading] = useState(0);
const initialRender = useRef(true);

 useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (!isLoading) {
      loadSuggestedPickups(0);
    }
  }, [currentPickups,selectedDate, isLoading]);

const formatNumber = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(number);
};

    const loadSuggestedPickups = (newPage) => {
        setCurrentPage(newPage);

      //  const currentPage = 0;
        const pageSize = 10;
        const weaponId = localStorage.getItem('activeWeapon');
        const pickups = JSON.parse(localStorage.getItem('activeRun'));

        const params = {
           page: newPage,
           size: pageSize,
           weaponId: weaponId,
           pickups: pickups,
        };

        if (selectedDate) {
           params.date = selectedDate;
        }

        axios.get('http://161.35.120.216:8080/api/builds', {
           params,
           paramsSerializer: params => {
               return qs.stringify(params, { arrayFormat: 'repeat' });
           }
        })
           .then(response => {
               setSuggestedPickups(response.data.content);
               setTotalPages(response.data.totalPages);
           })
           .catch(error => {
               console.error('Error fetching suggested builds:', error);
           });
    };

    return (
        <div className="suggested-pickups">
            <h2>Builds</h2>
            <table className="suggested-pickups-table">
                <tbody>
                    {suggestedPickups.map((build) => (
                        <tr key={build.id}>
                            <td className="pickup-icons">
                                {build.pickups.map((pickupId) => {
                                    const pickup = pickups.find(p => p.id === pickupId);
                                    const isHighlighted = currentPickups.some(currentPickup => currentPickup.id === pickupId);
                                    return (
                                        <div key={pickupId} className={`current-pickup-item ${isHighlighted ? 'highlight' : ''}`}>
                                            <div className="pickup-image-container">
                                                <img
                                                    src={`/images/pickups/${pickup.name.replace(' ', '_')}.png`}
                                                    alt={pickup.name}
                                                    className="current-pickup-icon"
                                                    onError={(e) => e.target.src = '/images/pickups/undefined.png'}
                                                />
                                                <img
                                                    src={`/images/gods/${pickup.god}.png`}
                                                    alt={`${pickup.god} icon`}
                                                    className="pickup-god-icon"
                                                    onError={(e) => e.target.src = '/images/gods/undefined.png'}
                                                />
                                                {pickup.god2 && (
                                                    <img
                                                        src={`/images/gods/${pickup.god2}.png`}
                                                        alt={`${pickup.god2} icon`}
                                                        className="pickup-god2-icon"
                                                        onError={(e) => e.target.src = '/images/gods/undefined.png'}
                                                    />
                                                )}
                                            </div>
                                            <div className="tooltip">
                                                <div className="pickup-name">{pickup.name}</div>
                                                <div className="pickup-description">{pickup.description}</div>
                                                <div className="pickup-subdescription">{pickup.subdescription}</div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </td>
                            <td>{build.damageType}</td>
                            <td>{formatNumber(build.damage)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-controls">
                <div className="pickup-name">{currentPage+1}/{totalPages}</div>
                <button onClick={() => loadSuggestedPickups(currentPage - 1)} disabled={currentPage === 0}>{'<'}</button>
                <button onClick={() => loadSuggestedPickups(currentPage + 1)} disabled={currentPage === totalPages - 1}>{'>'}</button>
            </div>
        </div>
    );
};

export default SuggestedPickups;
