import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../components/styles.css';
import { useNavigate } from 'react-router-dom';

const MyAccountPage = () => {
  const [stats, setStats] = useState({});
  const [runs, setRuns] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('http://161.35.120.216:8080/api/users/stats', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
    .then(response => {
      setStats(response.data.stats);
      setRuns(response.data.runs);
    })
    .catch(error => {
      console.error('Error fetching account data', error);
    });
  }, []);

  return (
    <div className="container">
      <div className="backdrop">
        <button className="top-left-button" onClick={() => navigate('/')}>←</button>
        <button className="top-right-button" onClick={() => navigate('/run')}>Continue Run</button>
        <h1 className="header">My Account</h1>
        <div className="stats-section">
          <h2>Statistics</h2>
          <p>Total Wins: {stats.totalWins}</p>
          <p>Win Ratio: {stats.winRatio}</p>
        </div>
        <div className="runs-section">
          <h2>Run History</h2>
          <ul>
            {runs.map(run => (
              <li key={run.id}>Run ID: {run.id}, Status: {run.status}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MyAccountPage;
