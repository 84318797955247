import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { usePickups } from './PickupsContext';
import { useWeapons } from '../components/WeaponsContext';
import qs from 'qs';
import PickupSelector from './PickupSelector';
import CurrentPickups from './CurrentPickups';
import SuggestedPickups from './SuggestedPickups';

const RunCalculator = ({ runId }) => {
    const { pickups, isLoading } = usePickups();
    const [choices, setChoices] = useState([null, null, null]);
    const [winPercentages, setWinPercentages] = useState([null, null, null]);
    const [selectedPickup, setSelectedPickup] = useState([null, null, null]);
      const [weaponModalOpen, setWeaponModalOpen] = useState(false);
      const [selectedWeapon, setSelectedWeapon] = useState(null);
        const { weapons } = useWeapons();
    const [modalOpen, setModalOpen] = useState([false, false, false]);
    const [displayedPickups, setDisplayedPickups] = useState([[], [], []]);
    const [pages, setPages] = useState([0, 0, 0]);
    const [searches, setSearches] = useState(["", "", ""]);
    const [currentPickups, setCurrentPickups] = useState([]);
    const [suggestedPickups, setSuggestedPickups] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const navigate = useNavigate();
    const pageSize = 10;

    useEffect(() => {
        if (!isLoading) {
            const storedActiveRun = localStorage.getItem('activeRun');
            if (storedActiveRun) {
                const activeRuns = JSON.parse(storedActiveRun);
                let detailedPickups = activeRuns.map(pickupId =>
                    pickups.find(p => p.id === pickupId)
                );
                detailedPickups = detailedPickups.filter((item, index, self) => {
                  // Use a Set to track seen IDs
                  const ids = self.map(obj => obj.id);
                  return ids.indexOf(item.id) === index;
                });

                 detailedPickups = [...detailedPickups].sort((a, b) => {
                      if (a.god < b.god) return -1;
                      if (a.god > b.god) return 1;
                      return 0;
                  });
                setCurrentPickups(detailedPickups);
            } else {
             startNewRun();
            }

            const initialDisplayedPickups = [
                pickups.slice(0, pageSize),
                pickups.slice(0, pageSize),
                pickups.slice(0, pageSize)
            ];
            setDisplayedPickups(initialDisplayedPickups);
        }
    }, [isLoading, pickups]);

    useEffect(() => {
    const date = "(07/16/2024)".split('(')[1].split(')')[0];
            const [month, day, year] = date.split('/');
            const formattedDate = `${year}-${month}-${day}`;
    setSelectedDate(formattedDate);
    }, []);

   /* const loadSuggestedPickups = () => {
        const currentPage = 0;
        const pageSize = 10;
        const weaponId = localStorage.getItem('activeWeapon');
        const pickups = JSON.parse(localStorage.getItem('activeRun'));

        const params = {
            page: currentPage,
            size: pageSize,
            weaponId: weaponId,
            pickups: pickups,
        };

        if (selectedDate) {
            params.date = selectedDate;
        }

        axios.get('http://161.35.120.216:8080/api/builds', {
            params,
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            }
        })
            .then(response => {
                setSuggestedPickups(response.data.content);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error('Error fetching suggested builds:', error);
            });
    };*/

    const handlePickSelection = (index) => {
        if (selectedPickup[index] !== null) {
            setChoices([null, null, null]);
            setWinPercentages([null, null, null]);
            setSelectedPickup([null, null, null]);
            const storedActiveRun = localStorage.getItem('activeRun');
            if (storedActiveRun) {
                const activeRuns = JSON.parse(storedActiveRun);
                let updatedActiveRun = [...activeRuns, selectedPickup[index].id];
                  updatedActiveRun = updatedActiveRun.filter((id, index, self) => self.indexOf(id) === index);
                localStorage.setItem('activeRun', JSON.stringify(updatedActiveRun));

                let detailedPickups = updatedActiveRun.map(pickupId =>
                    pickups.find(p => p.id === pickupId)
                );
                 detailedPickups = detailedPickups.filter((item, index, self) => {
                                  // Use a Set to track seen IDs
                                  const ids = self.map(obj => obj.id);
                                  return ids.indexOf(item.id) === index;
                                });

                                 detailedPickups = [...detailedPickups].sort((a, b) => {
                                      if (a.god < b.god) return -1;
                                      if (a.god > b.god) return 1;
                                      return 0;
                                  });
                setCurrentPickups(detailedPickups);
            }

          //  loadSuggestedPickups();
        }
    };

    const handleFailRun = () => {
        startNewRun();
    };
      const startNewRun = () => {
        setWeaponModalOpen(true);
      };

      const confirmWeaponSelection = (weapon) => {
      setSelectedWeapon(weapon);
            setWeaponModalOpen(false);
            localStorage.setItem('activeWeapon', weapon.id);
            localStorage.setItem('activeRun', JSON.stringify([]));
             setChoices([null, null, null]);
             setCurrentPickups([]);
           //   loadSuggestedPickups();
      };
    const handleCompleteRun = () => {
        axios.put(`http://161.35.120.216:8080/api/runs/complete`)
            .then(() => {
                navigate('/');
            })
            .catch(error => {
                console.error('Error completing run:', error);
            });
    };

    const handleAddBuild = () => {
        // Handle the Add Build action
        console.log("Add Build clicked");
    };

    const handlePatchChange = (event) => {
        const selectedPatch = event.target.value;
        if (selectedPatch === "All builds") {
            setSelectedDate(null);
        } else {
            const date = selectedPatch.split('(')[1].split(')')[0];
            const [month, day, year] = date.split('/');
            const formattedDate = `${year}-${month}-${day}`;
            setSelectedDate(formattedDate);
        }
    };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <h1 className="header">  <div className="controls">
                                                   <button className="pick-button" onClick={handleFailRun}>New Run</button>
                                                   <select className="pick-button-long" onChange={handlePatchChange}>
                                                   <option>Patch 4 (07/16/2024)</option>
                                                   <option>Patch 3 (06/26/2024)</option>
                                                   </select>
                                               </div></h1>

            <div className="pickup-container">
                {choices.map((choice, index) => (
                    <PickupSelector
                        key={index}
                        index={index}
                        choices={choices}
                        setChoices={setChoices}
                        winPercentages={winPercentages}
                        setWinPercentages={setWinPercentages}
                        selectedPickup={selectedPickup}
                        setSelectedPickup={setSelectedPickup}
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        displayedPickups={displayedPickups}
                        setDisplayedPickups={setDisplayedPickups}
                        pages={pages}
                        setPages={setPages}
                        searches={searches}
                        setSearches={setSearches}
                        pickups={pickups}
                        handlePickSelection={handlePickSelection}
                        selectedDate={selectedDate} // Pass selectedDate as a prop
                    />
                ))}
            </div>
            <CurrentPickups currentPickups={currentPickups} />
            <SuggestedPickups
                suggestedPickups={suggestedPickups}
                setSuggestedPickups={setSuggestedPickups} // Pass setSuggestedPickups as a prop
                pickups={pickups}
                currentPickups={currentPickups}
                selectedDate={selectedDate} // Pass selectedDate as a prop
            />

             {weaponModalOpen && (
                    <div className="modal-overlay" onClick={() => {
                           const storedActiveRun = localStorage.getItem('activeRun');
                                if (storedActiveRun) {
                        setWeaponModalOpen(false);}}}>
                    }
                      <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <h2>Select a Weapon</h2>
                        <div className="weapon-grid">
                          {weapons.map(weapon => (
                            <div
                              key={weapon.id}
                              className={`weapon-box ${selectedWeapon && selectedWeapon.id === weapon.id ? 'selected' : ''}`}
                              onClick={() => confirmWeaponSelection(weapon)}
                            >
                              <img src={`/images/weapons/${weapon.icon}`} alt={weapon.name} className="weapon-icon" />
                              <div className="tooltip">
                                {weapon.name}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
        </div>
    );
};

export default RunCalculator;
