import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../components/styles.css';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://161.35.120.216:8080/api/users/login', { email, password });
      localStorage.setItem('token', response.data); // Store token in localStorage
      navigate('/');
    } catch (error) {
      console.error('Login error', error);
    }
  };

  return (
    <div className="container">
      <div className="backdrop">
        <button className="top-left-button" onClick={() => navigate('/')}>←</button>
        <button className="top-right-button" onClick={() => navigate('/register')}>Register</button>
        <h1 className="header">Login</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <label>Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <button  className="pick-button" type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
